import {
    IFile,
    ITemplate,
    /*ITemplateCreateParams,*/
    ITemplateUpdateParams,
    TCommonPagination,
    TGetTemplateResponse,
} from "types";
import api from "./axios";

const getEmailTemplates = async () => {
    const response = await api.get<TGetTemplateResponse, TGetTemplateResponse>("/api/templates", {
        params: {
            type: "email",
        },
    });

    return response;
};

const searchTemplatesByName = async (types: string[], name: string) => {
    const response = await api.get<TCommonPagination<ITemplate>, TCommonPagination<ITemplate>>("/api/templates", {
        params: {
            "in[type]": types.join(","),
            name,
            limit: 100,
        },
    });

    return response;
};

const createTemplate = async (params: FormData) => {
    const response = await api.post<ITemplate, ITemplate>("/api/templates", params, {
        headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
        },
    });

    return response;
};

const getSMSTemplates = async () => {
    const response = await api.get<TGetTemplateResponse, TGetTemplateResponse>("/api/templates", {
        params: {
            type: "sms",
        },
    });

    return response;
};

const getTemplate = async (id: number) => {
    const response = await api.get<ITemplate, ITemplate>(`/api/templates/${id}`);

    return response;
};

const deleteTemplate = async (id: number) => {
    const response = await api.delete<ITemplate, ITemplate>(`/api/templates/${id}`);

    return response;
};

const updateTemplate = async (id: number, params: ITemplateUpdateParams) => {
    const response = await api.put<ITemplate, ITemplate>(`/api/templates/${id}`, params);

    return response;
};

const uploadAttachmentFile = async (templateId: number, params: FormData) => {
    const response = await api.post<IFile, IFile>(`/api/templates/${templateId}/attachment`, params, {
        headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
        },
    });

    return response;
};

const sendTestEmail = async (params: FormData) => {
    const response = await api.post(`/api/templates/test-email`, params, {
        headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
        },
    });

    return response;
};

const sendTestSMS = async (params: FormData) => {
    const response = await api.post(`/api/templates/test-sms`, params, {
        headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
        },
    });

    return response;
};

export const TemplateAPI = {
    getEmailTemplates,
    searchTemplatesByName,
    createTemplate,
    getSMSTemplates,
    getTemplate,
    updateTemplate,
    uploadAttachmentFile,
    sendTestEmail,
    sendTestSMS,
    deleteTemplate,
};
