import { useQuery } from "@tanstack/react-query";

import { TemplateAPI } from "apis/template";
import { TCommonPagination, ITemplate } from "types";

export function useSearchTemplatesByNameQuery(key: string, types: string[], searchQuery: string, enabled: boolean) {
    return useQuery<TCommonPagination<ITemplate>, Error>(
        [key, searchQuery],
        () => TemplateAPI.searchTemplatesByName(types, searchQuery),
        {
            refetchOnWindowFocus: false,
            enabled,
        }
    );
}

export function useGetTemplate(id: number, enabled: boolean) {
    return useQuery<ITemplate, Error>(["getTemplate", id], () => TemplateAPI.getTemplate(id), {
        refetchOnWindowFocus: false,
        enabled,
    });
}
