import React from "react";

import { Button, Dropdown, MenuProps } from "antd";
import { DeleteOutlined, UpOutlined } from "@ant-design/icons";

import { IFormFooterProps } from "types";
import { FormFooterWrapper } from "./FormFooter.styled";

export default function FormFooter({
    isLoading = false,
    canDelete = false,
    isActionTemplate = false,
    onCancel,
    onDelete,
    setTypesSubmit,
    extraActions,
    isTemplate,
    showModal,
    isUpdateTemplateQuickReply,
}: IFormFooterProps) {
    const items: MenuProps["items"] = [
        {
            label: (
                <Button
                    type="link"
                    size="large"
                    htmlType="submit"
                    onClick={() => setTypesSubmit && setTypesSubmit(["sms", "quick_reply"])}
                >
                    Save and {isUpdateTemplateQuickReply ? "Update" : "Create"} template
                </Button>
            ),
            key: "0",
        },
    ];
    return (
        <FormFooterWrapper>
            <div className="left-actions">
                {canDelete && (
                    <Button type="default" danger size="large" onClick={onDelete} icon={<DeleteOutlined />}>
                        Delete
                    </Button>
                )}
                {extraActions && <>{extraActions}</>}
            </div>
            <div className="right-actions">
                <Button type="default" size="large" onClick={onCancel}>
                    Cancel
                </Button>
                {isActionTemplate && !isTemplate && (
                    <Button type="link" size="large" onClick={showModal}>
                        Use template
                    </Button>
                )}
                {!isActionTemplate && (
                    <Button type="primary" size="large" htmlType="submit" loading={isLoading}>
                        Save
                    </Button>
                )}
                {isActionTemplate && (
                    <Dropdown.Button
                        icon={<UpOutlined />}
                        menu={{ items }}
                        style={{ marginLeft: "8px" }}
                        size="large"
                        type="primary"
                        className="btn-submit"
                        onClick={() => setTypesSubmit && setTypesSubmit(["sms"])}
                    >
                        Save
                    </Dropdown.Button>
                )}
            </div>
        </FormFooterWrapper>
    );
}
