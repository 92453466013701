import { useMutation } from "@tanstack/react-query";

import { TemplateAPI } from "apis/template";
import { ITemplateUpdateParams } from "types";

export function useCreateTemplateMutation() {
    return useMutation((params: FormData) => TemplateAPI.createTemplate(params));
}

export function useUpdateTemplateMutation(id: number) {
    return useMutation((params: ITemplateUpdateParams) => TemplateAPI.updateTemplate(id, params));
}

export function useUploadAttachmentFile() {
    return useMutation(({ templateId, formData }: { templateId: number; formData: FormData }) =>
        TemplateAPI.uploadAttachmentFile(templateId, formData)
    );
}

export function useSendTestEmailMutation() {
    return useMutation(({ formData }: { formData: FormData }) => TemplateAPI.sendTestEmail(formData));
}

export function useDeleteTemplateMutation() {
    return useMutation((id: number) => TemplateAPI.deleteTemplate(id));
}
