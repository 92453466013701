/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React from "react";

import { UploadOutlined, DeleteOutlined, FileTwoTone, StarFilled } from "@ant-design/icons";
import type { UploadProps, UploadFile } from "antd";
import { Button, Form, Input, List, Modal, Select, Space, Upload } from "antd";
import { isEmpty } from "lodash-es";
import { shallow } from "zustand/shallow";
import { RcFile, UploadChangeParam } from "antd/es/upload";

import useStore, { RFState } from "stores";
import { IFormsProps } from "../../../types";
import TinyMCEEditor from "../../TinyMCEEditor/TinyMCEEditor";
import FormFooter from "../FormFooter";
import TemplateTagDropdown from "components/TemplateTagDropdown";
import { useGetTemplate } from "hooks/features/templates/queries";
import {
    useCreateTemplateMutation,
    useDeleteTemplateMutation,
    useUpdateTemplateMutation,
    useUploadAttachmentFile,
} from "hooks/features/templates/mutations";
import { ListItemTemplates, UploadItemList } from "./SendSMSForm.styled";
import { useRemoveFile } from "hooks/features/files/mutations";
import Spinner from "components/Spinner";
import { useQueryClient } from "@tanstack/react-query";

interface IFormInputs {
    template: string;
    to?: string;
    from: string;
    message: string;
    messageHtml?: string;
    attachmentFiles: UploadChangeParam;
}

const selector = (state: RFState) => ({
    conditionFields: state.conditionFields,
});

const phonePattern = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/g;
const phoneTagPattern = /^{{phone}}$/g;

export default function SendSMSForm({ initialData, canDelete = true, onCancel, onSubmit, onDelete }: IFormsProps) {
    const [fileList, setFileList] = React.useState<UploadFile[]>([]);
    const [text, setText] = React.useState<string>("");
    const [listForms, setListForms] = React.useState<{ label: string; value: number }[]>([]);
    const [selectedTemplateIds, setSelectedTemplateIds] = React.useState<number[]>([]);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [idDeleted, setIdDeleted] = React.useState<number>(-1);
    const [deleteLoading, setDeleteLoading] = React.useState(false);
    const [form] = Form.useForm();
    const { conditionFields } = useStore(selector, shallow);
    const createTemplateMutation = useCreateTemplateMutation();
    const updateTemplateMutation = useUpdateTemplateMutation(
        Number(initialData?.templateId ?? initialData?.idTemplateQuickReply)
    );
    const uploadAttachmentFile = useUploadAttachmentFile();
    const removeFile = useRemoveFile();
    const queryClient = useQueryClient();
    const [typesSubmit, setTypesSubmit] = React.useState<string[]>([]);
    const { data: templateDetailData, isLoading: isTemplateDetailDataLoading } = useGetTemplate(
        Number(initialData?.templateId),
        !!initialData?.templateId
    );
    const deleteTemplate = useDeleteTemplateMutation();

    const initialValues = {
        templateId: String(initialData?.templateId ?? ""),
        to: String(initialData?.to ?? ""),
        message: String(initialData?.message ?? ""),
    };

    React.useEffect(() => {
        if (initialData && initialData.listForms && initialData.listForms.length > 0) {
            const dataForms = initialData.listForms.map((item: { name: string; id: number; type: string }) => ({
                label: item.name,
                value: item.id,
                emoji: <FileTwoTone twoToneColor={item.type === "form" ? "rgb(22, 119, 255)" : "orange"} />,
            }));
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setListForms(dataForms);
        }

        if (initialData && initialData.templateIds) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setSelectedTemplateIds(initialData.templateIds);
        }
    }, [initialData]);

    const getAttachmentFileUrl = (url: string) => {
        return `${process.env.REACT_APP_BASE_URL || "https://app.pilotpractice.com"}/${url}`;
    };

    const uploadProps: UploadProps = {
        ...(!!initialData?.templateId && {
            customRequest: async (options: any) => {
                const { onSuccess, onError } = options;
                try {
                    const data = new FormData();
                    data.append("attachmentFile", options.file as RcFile);

                    const attachmentFile = await uploadAttachmentFile.mutateAsync({
                        templateId: Number(initialData?.templateId),
                        formData: data,
                    });
                    setFileList([
                        ...fileList,
                        {
                            uid: String(attachmentFile.id),
                            name: attachmentFile.originalName,
                            status: "done",
                            url: getAttachmentFileUrl(attachmentFile.url),
                        },
                    ]);
                    onSuccess("Ok");
                } catch (e) {
                    onError("Error");
                }
            },
        }),
        beforeUpload(file) {
            if (!initialData?.templateId) {
                setFileList([...fileList, file]);

                return false;
            }
        },
        onRemove: async (file) => {
            try {
                if (initialData?.templateId) {
                    await removeFile.mutateAsync(Number(file.uid));
                }
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                setFileList(newFileList);
            } catch (e) {
                console.log(e);
            }
        },
        fileList,
        itemRender: (originNode) => {
            return <UploadItemList>{originNode}</UploadItemList>;
        },
        showUploadList: {
            removeIcon: <DeleteOutlined />,
        },
    };

    const getAttachmentFiles = React.useCallback(() => {
        return (
            templateDetailData?.attachmentFiles?.map((attachmentFile) => {
                return {
                    uid: String(attachmentFile.id),
                    name: attachmentFile.originalName,
                    status: "done",
                    url: getAttachmentFileUrl(attachmentFile.url),
                } as UploadFile;
            }) ??
            initialData?.attachmentFiles?.map((attachmentFile: any) => {
                return {
                    uid: String(attachmentFile.id),
                    name: attachmentFile.originalName,
                    status: "done",
                    url: getAttachmentFileUrl(attachmentFile?.url),
                } as UploadFile;
            }) ??
            []
        );
    }, [initialData?.attachmentFiles, templateDetailData?.attachmentFiles]);

    const submit = (data: IFormInputs) => {
        let newTemplateId = initialData?.templateId ?? null;
        typesSubmit.forEach(async (type: string) => {
            if (
                (!initialData?.templateId && type === "sms") ||
                (type === "quick_reply" && !initialData?.idTemplateQuickReply)
            ) {
                const formData = new FormData();
                formData.append("name", initialData.label as string);
                formData.append("type", type);
                formData.append("to", String(data.to));
                formData.append("from", String(data.from));
                formData.append("content", text);
                selectedTemplateIds.forEach((id: number) => {
                    formData.append("template_ids[]", id.toString());
                });

                fileList?.forEach((file: UploadFile) => {
                    if (file instanceof File) {
                        formData.append("attachment_files[]", file as RcFile);
                    } else {
                        formData.append("attachment_file_ids[]", file.uid);
                    }
                });
                const response = await createTemplateMutation.mutateAsync(formData);

                newTemplateId = response.id;
            } else {
                await updateTemplateMutation.mutateAsync({
                    name: initialData.label as string,
                    to: data.to,
                    from: data.from,
                    content: text,
                });
            }

            if (type === "sms") {
                onSubmit({
                    ...data,
                    message: text,
                    messageHtml: data?.message,
                    type: "sms",
                    idTemplateQuickReply: undefined,
                    ...(newTemplateId ? { templateId: newTemplateId } : { templateId: Number(initialData.templateId) }),
                });
            } else {
                queryClient.invalidateQueries(["getListTemplatesQuickReply"]);
            }
        });
    };

    React.useEffect(() => {
        if (typesSubmit.length > 0) {
            form.submit();
        }
    }, [form, typesSubmit]);

    const handleDeleteTemplate = async (e: any, id: number) => {
        e.stopPropagation();
        setIdDeleted(id);
        setDeleteLoading(true);
        await deleteTemplate.mutateAsync(id);

        await queryClient.invalidateQueries(["getListTemplatesQuickReply"]);
        setIdDeleted(-1);
        setDeleteLoading(false);
    };
    const handleSelectedTemplate = (item: any) => {
        setText(item.content);

        onSubmit({
            to: isEmpty(item.to) || phonePattern.test(item.to) || phoneTagPattern.test(item.to) ? item.to : "",
            from: item.from,
            message: item.content,
            messageHtml: item.content,
            templateIds: item.templates.map((template: { id: number }) => template.id),
            attachmentFiles: item.attachmentFiles,
            type: item.type,
            idTemplateQuickReply: item.id,
            label: item.name,
        });
    };

    const updateFieldValueWithTag = (fieldName: string, value: string) => {
        const currentValue = form.getFieldValue(fieldName);

        form.setFieldValue(fieldName, `${currentValue}{{${value}}}`);
    };

    React.useEffect(() => {
        if ((!!initialData?.templateId && !!templateDetailData) || !!initialData?.attachmentFiles) {
            setFileList(() => [...getAttachmentFiles()]);
        }
    }, [initialData, templateDetailData]);

    React.useEffect(() => {
        if (initialData && !isEmpty(initialData)) {
            form.setFieldValue("templateId", String(initialData?.templateId ?? ""));
            form.setFieldValue("to", String(initialData?.to ?? ""));
            form.setFieldValue("from", String(initialData?.from ?? ""));
            form.setFieldValue("message", String(initialData?.messageHtml ?? ""));
            form.setFieldValue("templateIds", initialData?.templateIds ?? []);

            setTypesSubmit([]);

            if (initialData?.templateId || initialData?.message) {
                const messageText = String(initialData?.message ?? "");
                setText(messageText);
            }
        }
    }, [initialData, form]);

    const handleChange = (value: number[]) => {
        setSelectedTemplateIds(value);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    if (!!initialData?.tempate && isTemplateDetailDataLoading) return <Spinner />;

    return (
        <div className="form-layout form-sms">
            <Form layout="vertical" form={form} initialValues={initialValues} onFinish={submit}>
                <Form.Item label="From" name="from">
                    <Input
                        placeholder="From"
                        suffix={<TemplateTagDropdown fieldName="from" onSelect={updateFieldValueWithTag} />}
                        style={{ width: "50%" }}
                    />
                </Form.Item>
                <Form.Item
                    label="To"
                    name="to"
                    rules={[
                        {
                            required: true,
                            message: "To is required field",
                        },
                        // {
                        //     message: "To can only be an phone number or {{phone}}",
                        //     validator: (_, value: string) => {
                        //         const phonePattern = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/g;
                        //         const phoneTagPattern = /^{{phone}}$/g;
                        //         if (isEmpty(value) || phonePattern.test(value) || phoneTagPattern.test(value)) {
                        //             return Promise.resolve();
                        //         }

                        //         return Promise.reject("To can only be an phone number or {{phone}}");
                        //     },
                        // },
                    ]}
                >
                    <Input
                        placeholder="To"
                        suffix={<TemplateTagDropdown fieldName="to" onSelect={updateFieldValueWithTag} />}
                        style={{ width: "50%" }}
                    />
                </Form.Item>
                <Form.Item
                    name="message"
                    label="Message"
                    rules={[{ required: true, message: "Message is required field" }]}
                >
                    <TinyMCEEditor
                        menubar=""
                        toolbar="undo redo | templatetags"
                        conditionFields={conditionFields}
                        onChangeText={(v) => setText(v)}
                    />
                </Form.Item>
                <Upload {...uploadProps}>
                    <Button icon={<UploadOutlined />}>Add attachment</Button>
                </Upload>
                <Form.Item name="templateIds" label="Add forms" style={{ marginTop: 10 }}>
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Select forms"
                        onChange={handleChange}
                        optionLabelProp="label"
                        options={listForms.map((item: any) => ({
                            label: (
                                <Space>
                                    <span role="img" aria-label={item.label}>
                                        {item.emoji}
                                    </span>
                                    {item.label}
                                </Space>
                            ),
                            value: item.value,
                        }))}
                    />
                </Form.Item>

                <FormFooter
                    onCancel={onCancel}
                    setTypesSubmit={setTypesSubmit}
                    isActionTemplate
                    canDelete={canDelete}
                    onDelete={onDelete}
                    isTemplate={!!initialData?.templateId}
                    showModal={showModal}
                    isUpdateTemplateQuickReply={!!initialData?.idTemplateQuickReply}
                />
            </Form>

            <Modal
                title="Select template quick reply"
                open={isModalOpen}
                okButtonProps={{ hidden: true }}
                onCancel={handleCancel}
            >
                {initialData?.listTemplateQuickReply.length > 0 ? (
                    <ListItemTemplates>
                        <List
                            itemLayout="horizontal"
                            dataSource={initialData.listTemplateQuickReply.reverse()}
                            renderItem={(item: { name: string; id: number; content: string }) => (
                                <List.Item onClick={() => handleSelectedTemplate(item)}>
                                    <List.Item.Meta
                                        avatar={<StarFilled />}
                                        title={item.name}
                                        description={<div dangerouslySetInnerHTML={{ __html: item.content }} />}
                                    />
                                    <Button danger type="link" onClick={(e: any) => handleDeleteTemplate(e, item.id)}>
                                        {deleteLoading && idDeleted === item.id ? <Spinner /> : <DeleteOutlined />}
                                    </Button>
                                </List.Item>
                            )}
                        />
                    </ListItemTemplates>
                ) : (
                    <Space>No item selected</Space>
                )}
            </Modal>
        </div>
    );
}
