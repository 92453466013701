import styled from "styled-components";

export const UploadItemList = styled.div`
    .ant-upload-list-item-name {
        flex: none !important;
    }
`;

export const ListItemTemplates = styled.div`
    & .ant-list-items {
        max-height: 300px;
        overflow: auto;
    }
    & .ant-list-item {
        padding-left: 10px;

        :hover {
            cursor: pointer;
            background-color: #8080802e;
        }
    }
`;
