import { Edge, Node, getOutgoers, MarkerType } from "reactflow";
import createGraphLayout from "./graphLayout";

export const traversalNodes = (children: any, node: Node, nodes: Node[], edges: Edge[]): void => {
    const outgoers = getOutgoers(node, nodes, edges);
    children.push(outgoers);

    if (outgoers.length > 0) {
        outgoers.forEach((outgoer) => {
            traversalNodes(children, outgoer, nodes, edges);
        });
    }
};

export const getChildrenOfNode = (node: Node, nodes: Node[], edges: Edge[]): Node[] => {
    const children: Node[] = [];

    traversalNodes(children, node, nodes, edges);

    return children.flat();
};

const getAdjacentNodes = (selectedNode: Node, nodes: Node[], edges: Edge[]) => {
    const nodeBefore = edges.find((edge) => edge.target === selectedNode.id)?.source;
    const nodeAfter = edges.find((edge) => edge.source === selectedNode.id)?.target;

    const beforeNode = nodes.find((node) => node.id === nodeBefore);
    const afterNode = nodes.find((node) => node.id === nodeAfter);

    return { beforeNode, afterNode };
};

export const getRemainingNodes = (
    selectedNode: Node,
    nodes: Node[],
    edges: Edge[]
): { nodes: Node[]; edges: Edge[] } => {
    if (selectedNode.data?.workflowType === "trigger") {
        return {
            nodes: [],
            edges: [],
        };
    }

    const selectedNodeChildren = getChildrenOfNode(selectedNode, nodes, edges);

    const { beforeNode, afterNode } = getAdjacentNodes(selectedNode, nodes, edges);

    const nextNodes = nodes
        .filter((nd) => nd.id !== selectedNode.id)
        .map((nd) => {
            if (selectedNodeChildren.findIndex((child) => child.id === nd.id) !== -1) {
                return {
                    ...nd,
                    data: {
                        ...nd.data,
                        parentId: selectedNode.data?.parentId,
                    },
                };
            }
            return nd;
        });

    const newEdges: Edge[] = [
        ...edges.filter((edge) => edge.source !== selectedNode.id && edge.target !== selectedNode.id),
    ];

    if (beforeNode && afterNode) {
        newEdges.push({
            id: `${beforeNode.id}-${afterNode.id}`,
            source: beforeNode.id,
            target: afterNode.id,
            type: "smoothstep",
            markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 25,
                height: 25,
            },
        });
    }

    return { nodes: createGraphLayout(nextNodes), edges: newEdges };
};

export const getBranchChildNodes = (node: Node, nodes: Node[], edges: Edge[], childNodes: Node[], finalNode?: Node) => {
    if (finalNode && node.id === finalNode.id) {
        return;
    }

    const outgoer = getOutgoers(node, nodes, edges)?.[0];

    if (outgoer) {
        childNodes.push(node);
        getBranchChildNodes(outgoer, nodes, edges, childNodes, finalNode);
    }
};

export const getRemovedBranchNodes = (
    node: Node,
    nodes: Node[],
    edges: Edge[],
    childNodes: Node[],
    finalNode?: Node
) => {
    const removedNodes: string[] = [];

    getBranchChildNodes(node, nodes, edges, childNodes, finalNode);

    const childNodesIds = childNodes.map((childNode) => childNode.id);

    return removedNodes.concat(String(node.id)).concat(childNodesIds);
};

interface NodesWithRemovedBranchesReturn {
    nodes: Node[];
    edges: Edge[];
}

export const getDepthestNodeInBranch = (branchId: string, nodes: Node[], edges: Edge[]): Node | undefined => {
    let depthestNode;
    let maxDepth = 0;
    const rootBranch = nodes.find((node) => node.id === branchId);
    if (rootBranch) {
        const finalNode = nodes.find((node) => node.id === rootBranch?.data?.finalActionId);
        const outgoers = getOutgoers(rootBranch, nodes, edges);
        outgoers.forEach((node) => {
            const childNodes: Node[] = [];
            getBranchChildNodes(node, nodes, edges, childNodes, finalNode);
            if (maxDepth < childNodes.length) {
                maxDepth = childNodes.length;
                depthestNode = childNodes?.slice(-1)?.[0];
            }
        });
    }
    return depthestNode;
};

export const getNodesWithRemovedBranches = (
    selectedNode: Node,
    nodes: Node[],
    edges: Edge[],
    newBranches?: Node[]
): NodesWithRemovedBranchesReturn => {
    let branchId: string;
    let nextNodes: Node[] = nodes;
    let nextEdges: Edge[] = edges;
    let removedBranchChildNodes: string[] = [];
    let finalNode: Node | undefined;
    if (selectedNode?.data?.nodeType === "branch") {
        branchId = selectedNode.id;
        finalNode = nodes?.find((node) => node.id === selectedNode?.data?.finalActionId);
        const outgoers = getOutgoers(selectedNode, nodes, edges);
        if (outgoers && outgoers.length > 0 && finalNode) {
            removedBranchChildNodes =
                outgoers
                    ?.filter((node) => !node?.data?.default)
                    .reduce<string[]>((removedNodes, nd) => {
                        if (
                            newBranches &&
                            newBranches.findIndex((branchNode: Node) => branchNode.id === nd.id) === -1
                        ) {
                            const childNodes: Node[] = [];

                            return removedNodes.concat(getRemovedBranchNodes(nd, nodes, edges, childNodes, finalNode));
                        }

                        return removedNodes;
                    }, []) ?? [];
        }
    } else {
        branchId = selectedNode?.data?.branchId;
        const rootBranch = nodes?.find((node) => node.id === branchId);
        finalNode = nodes?.find((node) => node.id === rootBranch?.data?.finalActionId);
        const parentId = selectedNode?.data?.parentId;
        removedBranchChildNodes = getRemovedBranchNodes(selectedNode, nodes, edges, [], finalNode);
        nextNodes = nextNodes.map((node) => {
            if (finalNode && finalNode.id === node.id) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        ...(selectedNode?.data?.parentId && { parentId }),
                    },
                };
            }

            return node;
        });
        if (parentId && finalNode && parentId !== branchId) {
            nextEdges = nextEdges.concat({
                id: `${parentId}-${finalNode.id}`,
                source: parentId,
                target: finalNode.id,
                type: "smoothStepButtonEdge",
                markerEnd: {
                    type: MarkerType.ArrowClosed,
                    width: 25,
                    height: 25,
                },
                data: {
                    showStartButton: true,
                },
            });
        }
    }

    nextNodes = nodes.filter((node) => !removedBranchChildNodes.includes(node.id));

    if (branchId) {
        const depthestNode = getDepthestNodeInBranch(branchId, nextNodes, nextEdges);
        if (finalNode && depthestNode) {
            nextNodes = nextNodes.map((node) => {
                if (node.id === finalNode?.id) {
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            parentId: depthestNode?.id,
                        },
                    };
                }

                return node;
            });
        }
    }

    return {
        nodes: createGraphLayout(nextNodes),
        edges: nextEdges,
    };
};

export const getNumberOfNodeWithinDefaultNode = (rootBranch: Node, nodes: Node[], edges: Edge[]) => {
    let numberOfNode = 0;
    const outgoers = getOutgoers(rootBranch, nodes, edges);
    const defaultNode = outgoers.find((node) => node?.data?.default);
    const finalNode = nodes.find((node) => node.id === rootBranch?.data?.finalActionId);
    if (defaultNode) {
        const childNodes: Node[] = [];
        getBranchChildNodes(defaultNode, nodes, edges, childNodes, finalNode);
        numberOfNode = childNodes.length;
    }

    return numberOfNode + 1;
};
